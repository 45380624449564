<template>
  <div class="nav-anchored">
    <div v-if="isToggleActive" class="language-picker">
      <!-- <b-icon icon="translate" height="16" width="19" style="margin-right:2px;"></b-icon> -->
      <img
        src="./../assets/language-icon.svg"
        class="lang-icon"
        style="margin-right: 0.2rem"
      />
      <template v-for="(language, index) in availableLanguages">
        <button
          :pressed="activeLanguage === language.id"
          :disabled="language.disabled"
          class="language-btn"
          :class="[
            (activeLanguage === 'all') & (language.id === 'en_US') && 'active',
            activeLanguage === language.id && 'active',
          ]"
          size="sm"
          variant="outline-dark"
          @click.prevent="setActiveLanguage(language.id)"
          :key="language.id + index"
        >
          {{ language.label }}
        </button>
        <div
          :key="language + index"
          v-if="index + 1 !== availableLanguages.length"
          class="btn-break"
        ></div>
      </template>
      <button class="lang-button" @click="showLanguage = !showLanguage">
        <img src="./../assets/language-icon.svg" style="margin-right: 0.2rem" />
      </button>
      <div class="mobile" :class="showLanguage && 'show'">
        <template v-for="(language, index) in availableLanguages">
          <button
            :pressed="activeLanguage === language.id"
            :disabled="language.disabled"
            class="language-btn-mobile"
            :class="[
              (activeLanguage === 'all') & (language.id === 'en_US') &&
                'active',
              activeLanguage === language.id && 'active',
            ]"
            size="sm"
            variant="outline-dark"
            @click.prevent="setActiveLanguage(language.id)"
            :key="language.id + index"
          >
            {{ language.label }}
          </button>
          <div
            :key="language + index"
            v-if="index + 1 !== availableLanguages.length"
            class="btn-break"
          ></div>
        </template>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "SelectLanguage",
  data() {
    return { showLanguage: false };
  },
  computed: {
    ...mapGetters("front", ["getMatchingProducts"]),
    ...mapGetters("front", ["languages"]),
    ...mapState("front", ["activeCategory", "activeLanguage", "languages"]),

    availableLanguages() {
      let updateLanguages = this.handleMatchingProducts(this.languages);
      // remove all cards selector
      updateLanguages = updateLanguages.filter((lang) => {
        if (lang.id !== "all") {
          return lang;
        }
      });
      return updateLanguages;
    },
    isToggleActive() {
      let languages = this.handleMatchingProducts(this.languages);
      let checkLanguage = languages.filter((item) => {
        if (item.id !== "all") {
          return item;
        }
      });
      if (
        checkLanguage.length > 1 &&
        checkLanguage.every((lang) => lang.disabled === false)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("front", ["setActiveLanguage"]),
    handleMatchingProducts(languages) {
      let matchingLanguages = languages
        .filter(
          (language) =>
            language.id === "all" ||
            this.$store.getters[
              "ping/parsedIdToken"
            ]?.userData?.languages?.indexOf(language.id) !== -1
        )
        .map((language) => {
          language.disabled =
            language?.id !== "all" &&
            this.getMatchingProducts(this?.activeCategory, language?.id)
              .length === 0;
          return language;
        });
      return matchingLanguages;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import "../scss/variables";

.nav-anchored {
  display: flex;
  align-items: center;

  button {
    white-space: nowrap;
  }
}

.lang-icon {
  @media (max-width: $phone-width) {
    display: none;
  }
}

.lang-button {
  display: none;
  @media (max-width: $phone-width) {
    display: block;
    border: 0;
    background-color: transparent;
  }
}

.language-picker {
  display: flex;
  align-items: center;
  height: -webkit-fill-available;
  flex-direction: row;
  position: relative;

  @media (max-width: $phone-width) {
    cursor: pointer;
  }
}

.btn-break {
  height: 18px;
  background-color: $base-black;
  width: 1px;

  @media (max-width: $phone-width) {
    display: none;
  }
}
.language-btn {
  background-color: transparent;
  font: $font-size-16;
  color: $base-black;
  padding: 0.2rem;
  border: 1px solid transparent;

  &:hover:not(.active) {
    background-color: transparent;
    text-decoration: none;
    border-bottom: 1px solid $base-black;
  }

  &.active {
    font-weight: 600;
    color: $highlight2;
  }

  @media (max-width: $phone-width) {
    display: none;
  }
}

.mobile {
  display: none;
  position: absolute;

  @media (max-width: $phone-width) {
    &.show {
      display: flex;
      flex-direction: column;
      top: 32px;
      left: 0;
      background-color: white;
      box-shadow: 1px 2px 10px -2px;
      padding: 0.6rem 0.4rem;
    }
  }
  .language-btn-mobile {
    background-color: transparent;
    font: $font-size-16;
    padding: 0.2rem;
    border: 1px solid transparent;

    &:hover:not(.active) {
      background-color: transparent;
      text-decoration: none;
      border-bottom: 1px solid $base-black;
    }

    &.active {
      font-weight: 600;
    }
  }
}
</style>
