/* eslint-disable no-console */
import clientConfig from '../util/clientConfig';
import Cookies from 'js-cookie';
import axios from 'axios';
axios.defaults.withCredentials = true;
const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&');

const generateNonce = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789:;_-.()!';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const userDataSchema = {
  favorites: [],
  languages: [],
  sender_profiles: [],
  recents: [],
  darkMode: false,
};

const profileSchema = {
  employeeID: '',
  supervisorID: '',
  location: '',
  role: '',
  reportingUsers: [],
};

const getRoleFromGroupStatement = (statement) => {
  const lower = statement?.toLowerCase() || null;
  const roles = {
    orderer: 'orderer',
    reporter: 'reporting',
    admin: 'clientadmin',
  };
  const keys = Object.keys(roles);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (lower?.includes(key)) {
      return roles[key];
    }
  }
  return null;
};

export default {
  namespaced: true,
  state: {
    authUrl: process.env.VUE_APP_PINGONE_AUTH_URL,
    apiUrl: process.env.VUE_APP_PINGONE_API_URL,
    proxyUrl: process.env.VUE_APP_PROXY_API_URL,
    environmentId: process.env.VUE_APP_PINGONE_ENVIRONMENT_ID,
    clientId: process.env.VUE_APP_PINGONE_CLIENT_ID,
    clientSecret: process.env.VUE_APP_PINGONE_CLIENT_SECRET,
    cookieDomain: process.env.VUE_APP_PINGONE_COOKIE_DOMAIN,
    landingUrl: process.env.VUE_APP_LANDING_URL,
    loginUrl: process.env.VUE_APP_LOGIN_URL,
    authenticated: false,
    flowId: null,
    flowInfo: {},
    passwordStatus: null,
    userTokens: null,
    userInfo: null,
    username: '',
    accountLinkUsername: '',
    password: '',
    newPassword: '',
    accountRegPassword: '',
    warningTitle: '',
    warningMessage: '',
    selectableDevices: [],
    devicePushMessage: '',
    socialProviders: [],
    validatePasswordUrl: '',
    validateIdentifierUrl: '',
    userVerifyUrl: '',
    validateOtpUrl: '',
    changePasswordUrl: '',
    passwordRecoverUrl: '',
    deviceSelectUrl: '',
    accountLinkUrl: '',
    passwordForgotUrl: '',
    registerUrl: '',
    passwordRecoverCode: '',
    view: 'DEFAULT',
    loading: false,
    config: {},
    loginErrors: [],
  },
  mutations: {
    setAuthenticated(state, payload) {
      state.authenticated = payload;
    },
    setAccountLinkUsername(state, payload) {
      state.accountLinkUsername = payload;
    },
    setFlowId(state, payload) {
      state.flowId = payload;
    },
    setFlowInfo(state, payload) {
      state.flowInfo = payload;
    },
    setPasswordStatus(state, payload) {
      state.passwordStatus = payload;
    },
    setUserTokens(state, payload) {
      state.userTokens = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setPassword(state, payload) {
      state.password = payload;
    },
    setWarningTitle(state, payload) {
      state.warningTitle = payload;
    },
    setWarningMessage(state, payload) {
      state.warningMessage = payload;
    },
    setSelectableDevices(state, payload) {
      state.selectableDevices = payload;
    },
    setDevicePushMessage(state, payload) {
      state.devicePushMessage = payload;
    },
    setSocialProviders(state, payload) {
      state.socialProviders = payload;
    },
    setValidatePasswordUrl(state, payload) {
      state.validatePasswordUrl = payload;
    },
    setValidateIdentifierUrl(state, payload) {
      state.validateIdentifierUrl = payload;
    },
    setUserVerifyUrl(state, payload) {
      state.userVerifyUrl = payload;
    },
    setValidateOtpUrl(state, payload) {
      state.validateOtpUrl = payload;
    },
    setChangePasswordUrl(state, payload) {
      state.changePasswordUrl = payload;
    },
    setPasswordRecoverUrl(state, payload) {
      state.passwordRecoverUrl = payload;
    },
    setDeviceSelectUrl(state, payload) {
      state.deviceSelectUrl = payload;
    },
    setAccountLinkUrl(state, payload) {
      state.accountLinkUrl = payload;
    },
    setPasswordForgotUrl(state, payload) {
      state.passwordForgotUrl = payload;
    },
    setRegisterUrl(state, payload) {
      state.registerUrl = payload;
    },
    setPasswordRecoverCode(state, payload) {
      state.passwordRecoverCode = payload;
    },
    setView(state, payload) {
      state.view = payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setConfig(state, payload) {
      state.config = payload;
    },
    setLoginErrors(state, payload) {
      state.loginErrors = payload;
    },
  },
  actions: {
    setRecentsCards({ dispatch, getters }, payload) {
      const id = payload;
      const recents = getters.parsedIdToken.userData?.recents || [];
      const newSalesforceUserData = getters.parsedIdToken.userData;
      let newRecents = recents;
      // let newRecents = ['1','2','3','4','5','6','7','8','9','10'];
      if (!recents.includes(id)) {
        newRecents = [id, ...newRecents];
      }
      if (recents.length >= 10) {
        newRecents.pop();
      }
      newSalesforceUserData.recents = newRecents;
      return dispatch('updateSalesforceUserData', newSalesforceUserData);
    },
    setDarkModePreference({ dispatch, getters }, payload) {
      const newSalesforceUserData = getters.parsedIdToken.userData;
      newSalesforceUserData.darkMode = payload;
      return dispatch('updateSalesforceUserData', newSalesforceUserData);
    },
    toggleFavorite({ dispatch, getters }, payload) {
      const id = payload;
      const favorites = getters.parsedIdToken.userData?.favorites || [];
      const newSalesforceUserData = getters.parsedIdToken.userData;
      let newFavorites = favorites.filter((productId) => productId !== id);
      // nothing was removed; wasn't already a favorite
      if (newFavorites.length === favorites.length) {
        newFavorites.push(id);
      }
      newSalesforceUserData.favorites = newFavorites;
      return dispatch('updateSalesforceUserData', newSalesforceUserData);
    },
    setConfig({ commit }, payload) {
      return new Promise((resolve) => {
        if (payload && payload in clientConfig) {
          commit('setConfig', clientConfig[payload]);
        } else {
          commit('setConfig', clientConfig.hbc);
        }
        resolve();
      });
    },
    doLogin({ state, getters }) {
      const nonce = generateNonce(60);
      const params = {
        response_type: 'code',
        client_id: state.clientId,
        redirect_uri: state.landingUrl,
        nonce: nonce,
        scope: 'openid profile address phone email p1:read:user p1:update:user',
        acr_values: getters.policy,
        post_logout_redirect_uri: state.loginUrl,
      };
      const queryString = Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');
      const authorizationUrl = `${state.authUrl}as/authorize?${queryString}`;

      Cookies.set('nonce', nonce, { domain: state.cookieDomain });

      window.location.href = authorizationUrl;
    },
    doLogout({ state, rootGetters }) {
      const idToken = Cookies.get('idToken');
      const accessToken = Cookies.get('accessToken');

      if (!idToken || !accessToken) {
        console.log('Unable to find a session for this user');

        window.location.replace(state.landingUrl);
      } else {
        Cookies.remove('idToken', { domain: state.cookieDomain });
        Cookies.remove('accessToken', { domain: state.cookieDomain });
        Cookies.remove('refreshToken', { domain: state.cookieDomain });
        Cookies.remove('uuid', { domain: state.cookieDomain });
        Cookies.remove('nonce', { domain: state.cookieDomain });
        Cookies.remove('autoredirect', { domain: state.cookieDomain });
        Cookies.remove('recipientData', { domain: state.cookieDomain });

        // If the user is being signed off automatically or has signed off and has Single Send SSO on
        // at the client level (and kept on because they were sent over with Recipient Data), we are
        // sending them to a Thank You page rather than a logon page.

        const logoffUrl = rootGetters['front/getClientConfig']?.singleSendSSO
          ? `${
              state.authUrl
            }as/signoff?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
              state.landingUrl + 'thankyou'
            )}`
          : `${
              state.authUrl
            }as/signoff?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
              state.loginUrl
            )}`;

        window.location.replace(logoffUrl);
      }
    },
    doStep({ commit, dispatch }, payload) {
      commit('startLoading');
      return new Promise((resolve, reject) => {
        axios({
          method: payload.method || 'post',
          url: payload.url,
          headers: {
            'Content-Type': payload.contentType,
          },
          data: payload.data,
          withCredentials: true,
        })
          .then((response) => {
            commit('setFlowInfo', response);
            return dispatch('nextStep');
          })
          .then((response) => {
            commit('setLoginErrors', []);
            commit('stopLoading');
            resolve(response);
          })
          .catch((error) => {
            let loginErrors = error?.response?.data?.details || [];
            for (var i in loginErrors) {
              var innerErrors = [];
              const innerErrorList = loginErrors[i].innerError;
              for (var j in innerErrorList?.unsatisfiedRequirements) {
                innerErrors.push(
                  innerErrorList[innerErrorList?.unsatisfiedRequirements[j]]
                );
              }
              loginErrors[i].innerError = innerErrors;
            }
            if (!loginErrors.length) {
              loginErrors = [
                'An unexpected problem occurred. Please try again or contact support.',
              ];
            }
            commit('setLoginErrors', loginErrors);
            commit('stopLoading');
            reject(error);
          });
      });
    },
    nextStep({ state, dispatch, commit }) {
      const { data } = state.flowInfo;
      const { status } = data;
      console.log('Parsing json to determine next step: ' + status);

      this.warningTitle = '';
      this.warningMessage = '';
      return new Promise((resolve, reject) => {
        switch (status) {
          case 'USERNAME_PASSWORD_REQUIRED':
            console.log('Rendering login form');
            commit(
              'setValidatePasswordUrl',
              data._links['usernamePassword.check'].href
            );
            break;
          case 'PASSWORD_REQUIRED':
            console.log('Rendering login form');
            commit(
              'setValidatePasswordUrl',
              data._links['usernamePassword.check'].href
            );
            break;
          case 'SIGN_ON_REQUIRED':
            console.log('Rendering identifier form');
            commit('setValidateIdentifierUrl', data._links['user.lookup'].href);
            break;
          case 'VERIFICATION_CODE_REQUIRED':
            console.log('Rendering verification form');
            commit('setUserVerifyUrl', data._links['user.verify'].href);
            break;
          case 'OTP_REQUIRED':
            console.log('Rendering otp form');
            commit('setValidateOtpUrl', data._links['otp.check'].href);
            break;
          case 'EXTERNAL_AUTHENTICATION_REQUIRED':
            console.log('Redirecting to external auth url');
            window.location.replace(
              data._embedded.identityProvider._links.authenticate.href
            );
            break;
          case 'MUST_CHANGE_PASSWORD':
            console.log('Rendering password form');
            commit('setChangePasswordUrl', data._links['password.reset'].href);
            break;
          case 'RECOVERY_CODE_REQUIRED':
            console.log('Rendering account recovery form');
            commit(
              'setPasswordRecoverUrl',
              data._links['password.recover'].href
            );
            break;
          case 'DEVICE_SELECTION_REQUIRED':
            console.log('Rendering device selection');
            commit('setDeviceSelectUrl', data._links['device.select'].href);
            commit('setSelectabledevices', data._embedded.devices);
            break;
          case 'ACCOUNT_LINKING_REQUIRED':
            console.log('Rendering account linking form');
            commit(
              'setAccountLinkUrl',
              data._links['usernamePassword.check'].href
            );
            commit('setAccountLinkUsername', data.attributes.username);
            break;
          case 'PUSH_CONFIRMATION_REQUIRED':
            console.log('Waiting for user to approve push');
            for (let i = 0; i < data._embedded.devices.length; i++) {
              if (data._embedded.devices[i].id == data.selectedDevice.id) {
                commit(
                  'setDevicePushMessage',
                  `Approval request sent to ${data._embedded.devices[i].model.marketingName}`
                );
              }
            }
            dispatch('getPushStatus', data._links.self.href)
              .then(resolve)
              .catch(reject);
            break;
          case 'PUSH_CONFIRMATION_TIMED_OUT':
            console.log('Push confirmation timed out');
            commit('setWarningTitle', 'Authentication failure');
            commit('setWarningMessage', 'Device approval timed out');
            break;
          case 'COMPLETED':
            console.log('Completed authentication successfully');
            console.log('Redirecting user');
            window.location.replace(data.resumeUrl);
            break;
          case 'FAILED':
            console.log('Authentication flow failure');
            commit('setWarningTitle', 'Unrecoverable error');
            commit('setWarningMessage', data.error.message);
            break;
          default:
            console.log('Unexpected outcome');
            break;
        }

        // check for enabled external identity providers

        if (data._embedded.socialProviders) {
          // yes we have external identity providers

          console.log('Social providers present');
          if (
            Cookies.get('autoredirect', {
              cookieDomain: state.cookieDomain,
            }) === 'true'
          ) {
            console.log(
              data._embedded.socialProviders[0]._links.authenticate.href
            );
            window.location.replace(
              data._embedded.socialProviders[0]._links.authenticate.href
            );
          }
          commit('setSocialProviders', data._embedded.socialProviders);
        }

        // check for account registration is enabled

        // if (data._links['user.register'].href) {

        if ('user.register' in data._links) {
          // yes the authentication policy has registration enabled

          console.log('Account registration is enabled');
          commit('setRegisterUrl', data._links['user.register'].href);

          // create a registration button

          // newButton = '<a href="#" onclick="showRegistration();" class="btn btn-secondary btn-lg mb-2 mr-1">Create Account</a>';
          // $('#selfServicePayload').append(newButton);
        }

        // check for account recovery is enabled

        if ('password.forgot' in data._links) {
          // yes account recovery is enabled

          console.log('Account recovery is enabled');
          commit('setPasswordForgotUrl', data._links['password.forgot'].href);

          // create a forgot password button

          // newButton = '<a href="#" onclick="showPasswordForgot();" class="btn btn-secondary btn-lg mb-2 mr-1">Forgot Password</a>';
          // $('#selfServicePayload').append(newButton);
        }

        resolve();
      });
    },
    exchangeCodeForToken({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/token`,
          baseURL: state.authUrl,
          auth: {
            username: state.clientId,
            password: state.clientSecret,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: (jsonData) => transformRequest(jsonData),
          data: {
            grant_type: 'authorization_code',
            code: payload,
            redirect_uri: state.landingUrl,
          },
        })
          .then(resolve)
          .catch(reject);
      });
    },
    exchangeRefreshToken({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/token`,
          baseURL: state.authUrl,
          auth: {
            username: state.clientId,
            password: state.clientSecret,
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: (jsonData) => transformRequest(jsonData),
          data: {
            grant_type: 'refresh_token',
            refresh_token: state.userTokens.refresh_token,
          },
        })
          .then((response) => {
            Cookies.set('accessToken', response.data.access_token, {
              domain: state.cookieDomain,
            });
            Cookies.set('idToken', response.data.id_token, {
              domain: state.cookieDomain,
            });
            Cookies.set('refreshToken', response.data.refresh_token, {
              domain: state.cookieDomain,
            });
            commit('setUserTokens', response.data);
          })
          .then(() => {
            if (!payload?.suppressResolveUserData) {
              return dispatch('resolveUserData');
            }
            return Promise.resolve();
          })
          .then(resolve)
          .catch((error) => {
            dispatch('doLogout');
            reject(error);
          });
      });
    },
    resolveUserData({ getters, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        let userUpdates = {};
        let roleChange = false;

        // set/update profile if needed
        let newProfile = {};
        const existingProfile = getters.parsedIdToken?.profile;
        const roleFromGroupStatement = getRoleFromGroupStatement(
          getters.parsedIdToken?.groupStatement
        );
        // has no profile
        if (!existingProfile) {
          newProfile = getters.defaultProfileData;
          if (roleFromGroupStatement) {
            roleChange = roleFromGroupStatement !== newProfile.role;
            newProfile.role = roleFromGroupStatement;
          }
          userUpdates.profile = newProfile;
        } else if (
          // profile has no role and default role is not blank OR
          (existingProfile?.role === '' &&
            getters.defaultProfileData.role !== '') ||
          // profile has no location and default location is not blank OR
          (existingProfile?.location === '' &&
            getters.defaultProfileData.location !== '') ||
          // role found in group statment and role found in group statment different from current
          (roleFromGroupStatement &&
            existingProfile?.role !== roleFromGroupStatement)
        ) {
          newProfile = Object.assign({}, existingProfile);
          if (roleFromGroupStatement) {
            roleChange = roleFromGroupStatement !== newProfile.role;
            newProfile.role = roleFromGroupStatement;
          }
          if (newProfile.role === '') {
            newProfile.role = getters.defaultProfileData.role;
          }
          userUpdates.profile = newProfile;
        }

        // set/update userData if needed
        let newUserData = {};
        const existingUserData = getters.parsedIdToken?.userData;
        // has no userData
        if (!existingUserData) {
          userUpdates.salesforceUserData = getters.defaultUserData;
        } else if (
          // userData has no languages and default userData has at least one OR
          (!existingUserData?.languages?.length &&
            getters.defaultUserData.languages.length) ||
          // userData has no sender profiles and default userData has at least one
          (!existingUserData?.sender_profiles?.length &&
            getters.defaultUserData.sender_profiles.length)
        ) {
          newUserData = Object.assign({}, existingUserData);
          if (
            !newUserData?.languages?.length &&
            getters.defaultUserData.languages.length
          ) {
            newUserData.languages = [...getters.defaultUserData.languages];
          }
          if (
            !newUserData?.sender_profiles?.length &&
            getters.defaultUserData.sender_profiles.length
          ) {
            newUserData.sender_profiles = [
              ...getters.defaultUserData.sender_profiles,
            ];
          }
          userUpdates.salesforceUserData = newUserData;
        }

        // no changes
        if (!Object.keys(userUpdates).length) {
          resolve();
        } else {
          // console.log(userUpdates,' userUpdates')
          // console.log(Object.keys(userUpdates))
          dispatch('updateUser', userUpdates)
            .then(() =>
              dispatch('exchangeRefreshToken', {
                suppressResolveUserData: true,
              })
            )
            .then(() => {
              // only attempt to resolve IDA roles if there was a role change AND
              // customer group has resolving IDA roles enabled
              if (
                roleChange &&
                rootState.ocapi?.customerGroup?.c_resolvePingIDARoles
              ) {
                return dispatch('resolveIDARole');
              }
              return Promise.resolve();
            })
            .then(resolve)
            .catch(reject);
        }
      });
    },
    evaluateTokens({ getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const expiration = new Date(getters.parsedAccessToken.exp * 1000);
        const now = new Date();
        if (expiration.getTime() > now.getTime()) {
          // tokens are valid
          resolve();
        } else {
          // refresh tokens
          dispatch('exchangeRefreshToken').then(resolve).catch(reject);
        }
      });
    },
    tokenIntrospection({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `as/introspect`,
          baseURL: state.authUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: false,
          transformRequest: (jsonData) => transformRequest(jsonData),
          data: {
            token: payload,
            client_id: state.clientId,
            client_secret: state.clientSecret,
          },
        })
          .then(resolve)
          .catch(reject);
      });
    },
    resolveIDARole({ getters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/Ping-ResolveIDARole`,
          baseURL: getters.workerUrl,
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
          data: {
            userID: getters.parsedIdToken.sub,
          },
        })
          .then(resolve)
          .catch(reject);
      });
    },
    resolveGroup({ getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `/Ping-ResolveGroup`,
          baseURL: getters.workerUrl,
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: false,
          data: payload,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    readUser({ state, getters }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `/environments/${state.environmentId}/users/${getters.parsedAccessToken.sub}`,
          baseURL: state.apiUrl,
          headers: {
            Authorization: `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: false,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    updateUser({ state, getters }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `/environments/${state.environmentId}/users/${getters.parsedAccessToken.sub}`,
          baseURL: state.apiUrl,
          headers: {
            Authorization: `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          data: {
            ...payload,
          },
          withCredentials: false,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    updateOtherUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'patch',
          url: `/environments/${state.environmentId}/users/${payload.userId}`,
          baseURL: state.apiUrl,
          headers: {
            Authorization: `Bearer ${state.userTokens.access_token}`,
            'Content-Type': 'application/json',
          },
          data: {
            ...payload.data,
          },
          withCredentials: false,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    updateSalesforceUserData({ dispatch }, payload) {
      return dispatch('updateUser', {
        salesforceUserData: payload,
      }).then(() =>
        dispatch('exchangeRefreshToken', { suppressResolveUserData: true })
      );
    },
    updateProfile({ dispatch }, payload) {
      return dispatch('updateUser', {
        profile: payload,
      }).then(() =>
        dispatch('exchangeRefreshToken', { suppressResolveUserData: true })
      );
    },
    lockUser({ dispatch }) {
      return dispatch('updateUser', {
        locked: {
          status: true,
          lockedOn: new Date(),
        },
      });
      /* .then(() => {
        commit('setLoginErrors', ['Your account has been locked out due to inactivity.  Please reach out to your client administrator to have your account unlocked.'])
        return dispatch('doLogout')
      }) */
    },
    changePassword({ dispatch, state }, payload) {
      console.log('changePassword called');
      return dispatch('doStep', {
        url: state.changePasswordUrl,
        contentType: 'application/vnd.pingidentity.password.reset+json',
        data: payload,
      });
    },
    deviceSelect({ dispatch, state }, payload) {
      console.log('deviceSelect called');
      return dispatch('doStep', {
        url: state.deviceSelectUrl,
        contentType: 'application/vnd.pingidentity.device.select+json',
        data: payload,
      });
    },
    getPushStatus({ dispatch }, payload) {
      console.log('getPushStatus called');
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          dispatch('doStep', {
            method: 'get',
            url: payload,
            contentType: 'application/json',
          })
            .then(resolve)
            .catch(reject);
        }, 2000);
      });
    },
    validatePassword({ dispatch, state }, payload) {
      console.log('validatePassword called');
      return dispatch('doStep', {
        url: state.validatePasswordUrl,
        contentType: 'application/vnd.pingidentity.usernamePassword.check+json',
        data: payload,
      });
    },
    validateIdentifier({ dispatch, state }, payload) {
      console.log('validateIdentifier called');
      return dispatch('doStep', {
        url: state.validateIdentifierUrl,
        contentType: 'application/vnd.pingidentity.user.lookup+json',
        data: payload,
      });
    },
    validateOtp({ dispatch, state }, payload) {
      console.log('validateOtp called');
      return dispatch('doStep', {
        url: state.validateOtpUrl,
        contentType: 'application/vnd.pingidentity.otp.check+json',
        data: payload,
      });
    },
    registerAccount({ dispatch, state }, payload) {
      console.log('registerAccount called');
      return dispatch('doStep', {
        url: state.registerUrl,
        contentType: 'application/vnd.pingidentity.user.register+json',
        data: payload,
      });
    },
    passwordForgot({ dispatch, commit, state }, payload) {
      console.log('passwordForgot called');
      return new Promise((resolve, reject) => {
        dispatch('doStep', {
          url: state.passwordForgotUrl,
          contentType: 'application/vnd.pingidentity.password.forgot+json',
          data: payload,
        })
          .then(() => {
            commit('setView', 'DEFAULT');
            resolve();
          })
          .catch(reject);
      });
    },
    passwordRecover({ dispatch, state }, payload) {
      console.log('passwordRecover called');
      return dispatch('doStep', {
        url: state.passwordRecoverUrl,
        contentType: 'application/vnd.pingidentity.password.recover+json',
        data: payload,
      });
    },
    accountLink({ dispatch, state }, payload) {
      console.log('accountLink called');
      return dispatch('doStep', {
        url: state.accountLinkUrl,
        contentType: 'application/vnd.pingidentity.usernamePassword.check+json',
        data: payload,
      });
    },
    userVerify({ dispatch, state }, payload) {
      console.log('userVerify called');
      return dispatch('doStep', {
        url: state.userVerifyUrl,
        contentType: 'application/vnd.pingidentity.user.verify+json',
        data: payload,
      });
    },
    checkFlowStatus({ dispatch, state }) {
      console.log('checkFlowStatus called');
      return dispatch('doStep', {
        method: 'get',
        url: `${state.authUrl}flows/${state.flowId}`,
        contentType: 'application/json',
      });
    },
    checkAuthentication({ state, commit, getters, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        commit('setFlowId', payload.query.flowId);
        console.log('Page ready function');

        // determine if user is already signed in, and show appropriate login/logout buttons

        // renderButtonState();

        // do I already have a session cookie?

        if (
          Cookies.get('accessToken', { domain: state.cookieDomain }) &&
          Cookies.get('uuid', { domain: state.cookieDomain })
        ) {
          // yes, user already has a session

          console.log('Existing session found.  Redirecting to landingUrl');

          // redirect to the landingUrl

          // window.location.replace(landingUrl);

          commit('setUserTokens', {
            access_token: Cookies.get('accessToken', {
              domain: state.cookieDomain,
            }),
            id_token: Cookies.get('idToken', { domain: state.cookieDomain }),
            refresh_token: Cookies.get('refreshToken', {
              domain: state.cookieDomain,
            }),
          });

          dispatch('evaluateTokens')
            .then(() => {
              commit('setAuthenticated', true);
              resolve();
            })
            .catch(reject);
        } else {
          // no, user does not already have a session

          console.log('Session not found');

          // parse the url fragment for any access token

          console.log('Parsing URL Fragment for tokens');

          console.log(payload);

          const querystring = `?${payload.hash.replace('#', '')}`;
          const params = new URLSearchParams(querystring);
          const userTokens = {};
          params.forEach((value, key) => {
            if (key === 'expires_in') {
              const now = new Date();
              userTokens.expiration = now.getTime() + value;
            } else {
              userTokens[key] = value;
            }
          });
          commit('setUserTokens', userTokens);

          // is there something in the URL fragment?

          const accessToken = state.userTokens.access_token;
          const idToken = state.userTokens.id_token;
          const refreshToken = state.userTokens.refresh_token;

          console.log({ userTokens });
          if (payload.query?.code) {
            dispatch('exchangeCodeForToken', payload.query.code)
              .then((response) => {
                commit('setUserTokens', response.data);
                Cookies.set('uuid', getters.parsedIdToken.sub, {
                  domain: state.cookieDomain,
                });
                Cookies.set('accessToken', response.data.access_token, {
                  domain: state.cookieDomain,
                });
                Cookies.set('idToken', response.data.id_token, {
                  domain: state.cookieDomain,
                });
                Cookies.set('refreshToken', response.data.refresh_token, {
                  domain: state.cookieDomain,
                });
                commit('setAuthenticated', true);
                resolve();
              })
              .catch(reject);
          } else if (accessToken && idToken && refreshToken) {
            // yes, we got an access token from the url fragment

            console.log('Access token received from URL fragment');

            // parse id token for subject and other details

            const idPayload = getters.parsedIdToken;

            // does requested nonce match returned nonce?

            const returnedNonce = idPayload.nonce;

            // let requestedNonce = window.localStorage.getItem('nonce');

            const requestedNonce = Cookies.get('nonce', {
              domain: state.cookieDomain,
            });

            if (returnedNonce == requestedNonce) {
              // yes, nonce matches

              Cookies.set('uuid', idPayload.sub, {
                domain: state.cookieDomain,
              });
              Cookies.set('accessToken', accessToken, {
                domain: state.cookieDomain,
              });
              Cookies.set('idToken', idToken, { domain: state.cookieDomain });
              Cookies.set('refreshToken', refreshToken, {
                domain: state.cookieDomain,
              });

              dispatch('evaluateTokens')
                .then(() => {
                  commit('setAuthenticated', true);
                  resolve();
                })
                .catch(reject);
            } else {
              // no, nonce does not match

              console.log('Nonce validation failed');

              console.log('Requested nonce: ' + requestedNonce);
              console.log('Returned nonce: ' + returnedNonce);

              commit('setWarningTitle', 'Nonce mismatch');
              commit(
                'setWarningMessage',
                'Unexpected nonce returned in id token'
              );

              commit('setAuthenticated', false);
              resolve();
            }
          } else {
            commit('setAuthenticated', false);
            resolve();
          }
        }
      });
    },
  },
  getters: {
    workerUrl(state) {
      return `${state.proxyUrl}sfra/`;
    },
    parsedAccessToken(state) {
      if (state.userTokens.access_token) {
        const base64Url = state.userTokens.access_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    parsedIdToken(state) {
      if (state.userTokens.id_token) {
        const base64Url = state.userTokens.id_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    parsedRefreshToken(state) {
      if (state.userTokens.refresh_token) {
        const base64Url = state.userTokens.refresh_token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
      }
    },
    policy(state) {
      return state.config.policy ? state.config.policy : 'Customer-Care';
    },
    defaultProfileData(state, getters, rootState) {
      const customerGroup = rootState.ocapi.customerGroup;
      const clientConfiguration =
        customerGroup && 'c_clientConfiguration' in customerGroup
          ? JSON.parse(customerGroup.c_clientConfiguration)
          : [];
      const profile = Object.assign({}, profileSchema);
      profile.location =
        clientConfiguration?.locations?.find((location) => location?.default)
          ?.value || '';
      profile.role = 'orderer';
      return profile;
    },
    defaultUserData(state, getters, rootState) {
      const customerGroup = rootState.ocapi.customerGroup;
      const senderProfiles =
        customerGroup && 'c_senderProfiles' in customerGroup
          ? JSON.parse(customerGroup.c_senderProfiles)
          : [];
      const userData = Object.assign({}, userDataSchema);
      console.log(userData)
      userData.languages = ['en_US'];
      userData.sender_profiles =
        senderProfiles
          ?.filter((profile) => profile?.newUserDefault || false)
          .map((profile) => profile.profileID) || [];
      return userData;
    },
  },
};
